import { getActionName } from './utils';
import * as types from './types';
const defaultKey = 'addressPicker';

export const initialState = {
  [defaultKey]: {
    postcode: '',
    loading: false,
    addressList: null,
    addressDetails: null,
    manualEntry: false,
    addressFields: {},
  },
};

export const getInitialState = (key = 'addressPicker') => ({
  [key]: {
    ...initialState[defaultKey],
  },
});

export default function(prefix, key = defaultKey) {
  return function(state, action) {
    switch (action.type) {
      case getActionName(types.GET_ADDRESS_LIST_REQUEST, prefix):
        return state.mergeDeep({
          [key]: {
            loading: true,
            postcode: action.postcode,
            selectedAddress: null,
          },
        });
      case getActionName(types.GET_ADDRESS_LIST_SUCCESS, prefix):
        return state.mergeDeep({
          [key]: {
            loading: false,
            addressList: action.addresses,
            selectedAddress:
              action.addresses && action.addresses.length > 0
                ? action.addresses[0].uuid
                : null,
          },
        });
      case getActionName(types.GET_ADDRESS_LIST_FAILURE, prefix):
        return state.mergeDeep({
          [key]: {
            loading: false,
            addressList: null,
            selectedAddress: null,
          },
        });

      case getActionName(types.SET_ADDRESS_SELECTION, prefix):
        return state.mergeDeep({
          [key]: {
            selectedAddress: action.uuid,
          },
        });

      case getActionName(types.SET_MANUAL_ENTRY, prefix):
        return state.setIn([key, 'manualEntry'], action.manualEntry);
      case getActionName(types.SET_MANUAL_ENTRY_FIELD, prefix):
        return state.setIn([key, 'addressFields', action.field], action.value);
      default:
        return state;
    }
  };
}
