/* eslint-disable no-console */

import { takeLatest, apply, select } from 'redux-saga/effects';
import { getClient } from 'app/lib/analytics';
import { ApplicationApi } from 'app/redux/modules/App/api';
import * as types from './constants';

function* trackPage({ pathname, addressObj }) {
  const analyticsClient = getClient();
  yield apply(analyticsClient, analyticsClient.page, [pathname, addressObj]);
}

function* trackEvent({ event, params = {} }) {
  const analyticsClient = getClient();
  yield apply(analyticsClient, analyticsClient.track, [event, params]);
}

function* trackEcommEvent({ event, products, params = {} }) {
  const analyticsClient = getClient();
  const applicationId = yield select((state) =>
    ApplicationApi.getCurrentApplication(state)
  );

  if (!products) products = [];

  const ecommParams = {
    products,
    transactionAttributes: {
      Id: applicationId,
      transactionId: applicationId, // for GTM
      Revenue: products.reduce((acc, val) => {
        return (acc += val.Price);
      }, 0),
    },
  };

  const updatedParams = {
    applicationId,
    gtm: true,
    ecommerce: true,
    ...ecommParams,
    ...params,
  };

  yield apply(analyticsClient, analyticsClient.track, [event, updatedParams]);
}

function* trackIdent({ id, params, reset }) {
  const analyticsClient = getClient();
  if (reset) {
    analyticsClient.newSession();
  }

  const [salesId, applicationId] = yield select((state) => [
    ApplicationApi.getSalesId(state),
    ApplicationApi.getCurrentApplication(state),
  ]);
  const updatedParams = { applicationId, ...params };
  yield apply(analyticsClient, analyticsClient.identify, [
    salesId || id,
    updatedParams,
  ]);
}

export default function*() {
  yield takeLatest(types.ANALYTICS_TRACK_PAGE, trackPage);
  yield takeLatest(types.ANALYTICS_TRACK_EVENT, trackEvent);
  yield takeLatest(types.ANALYTICS_TRACK_EVENT_ECOMM, trackEcommEvent);
  yield takeLatest(types.ANALYTICS_TRACK_IDENT, trackIdent);
}
