import Immutable from 'immutable';
import * as types from './constants';

const initialState = Immutable.fromJS({
  optlyReady: true,
  snowplowReady: true,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.ANALYTICS_OPTLY_SET_READY:
      return state.set('optlyReady', action.ready);
    case types.ANALYTICS_SNOWPLOW_SET_READY:
      return state.set('snowplowReady', action.ready);
    default:
      return state;
  }
}
