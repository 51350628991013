import Immutable from 'immutable';
import ukAddressParser from 'redux/utils/UKAddressParser';
import ukAddressSorter from 'redux/utils/UKAddressSorter';
import isValidPostcode from 'redux/services/isValidPostcode';

import {
  failedRequestState,
  fetchingRequestState,
  successRequestState,
  createRequestStateObject,
} from 'redux/utils/isRequestInProgress';

import { getActionName } from './utils';
import {
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_LOADING,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAILURE,
  GET_ADDRESS_DETAILS_REQUEST,
  GET_ADDRESS_DETAILS_LOADING,
  GET_ADDRESS_DETAILS_SUCCESS,
  GET_ADDRESS_DETAILS_FAILURE,
  SAVE_SELECTED_ADDRESSS_ID,
  TOGGLE_ADDRESS_NOT_LISTED,
  UPDATE_FIELD_ADDRESS_NOT_LISTED,
  UPDATE_POSTCODE_FIELD,
  VALIDATE_POSTCODE_FIELD,
  ADDRESS_PICKER_RESET,
  ADDRESS_SELECT,
} from './constants';

const REQ_STATE_GET_ADDRESSES = 'getAddresses';
const REQ_STATE_GET_ADDRESS_DETAILS = 'getAddressDetail';

const initialState = Immutable.fromJS({
  addresses: [],
  addressesFetching: false,
  addressesError: null,
  selectedAddressId: null,
  addressDetails: {},
  addressNotListed: false,
  postcode: '',
  addressDetailsNotListed: {
    flatNumber: '',
    buildingName: '',
    buildingNumber: '',
    street: '',
    town: '',
    postcode: '',
  },
  ...createRequestStateObject([
    REQ_STATE_GET_ADDRESSES,
    REQ_STATE_GET_ADDRESS_DETAILS,
  ]),
});

const sortAddresses = (addresses) =>
  addresses
    .map((a) => ({
      ...a,
      primaryText: ukAddressParser(a),
    }))
    .sort((a, b) => ukAddressSorter(a, b));

export default function addressPickerReducerFactory(name = null) {
  return function addressPickerReducer(state = initialState, action) {
    switch (action.type) {
      case getActionName(GET_ADDRESSES_REQUEST, name):
        return fetchingRequestState(state, REQ_STATE_GET_ADDRESSES).mergeDeep({
          addressesError: null,
          addressesFetching: true,
        });

      case getActionName(GET_ADDRESSES_LOADING, name):
        return fetchingRequestState(state, REQ_STATE_GET_ADDRESSES).mergeDeep({
          addressesFetching: true,
        });

      case getActionName(GET_ADDRESSES_SUCCESS, name):
        return successRequestState(state, REQ_STATE_GET_ADDRESSES).merge({
          addresses: sortAddresses(action.addresses),
          addressesFetching: false,
          selectedAddressId: action.addresses[0].uuid,
        });

      case getActionName(GET_ADDRESSES_FAILURE, name):
        return failedRequestState(state, REQ_STATE_GET_ADDRESSES)
          .set('addresses', [])
          .mergeDeep({
            addressesError: action.error,
            addressesFetching: false,
          });

      /**
       * GET_ADDRESS_DETAILS_*
       */

      case getActionName(GET_ADDRESS_DETAILS_REQUEST, name):
        return fetchingRequestState(
          state,
          REQ_STATE_GET_ADDRESS_DETAILS
        ).mergeDeep({
          addressDetails: { [action.uuid]: 'request' },
        });

      case getActionName(GET_ADDRESS_DETAILS_LOADING, name):
        return fetchingRequestState(
          state,
          REQ_STATE_GET_ADDRESS_DETAILS
        ).mergeDeep({
          addressDetails: { [action.uuid]: 'loading' },
        });

      case getActionName(GET_ADDRESS_DETAILS_SUCCESS, name):
        return successRequestState(
          state,
          REQ_STATE_GET_ADDRESS_DETAILS
        ).mergeDeep({
          addressDetails: { [action.uuid]: action.details },
        });

      case getActionName(GET_ADDRESS_DETAILS_FAILURE, name):
        return failedRequestState(
          state,
          REQ_STATE_GET_ADDRESS_DETAILS
        ).mergeDeep({
          addressDetails: { [action.uuid]: action.error },
        });

      case getActionName(SAVE_SELECTED_ADDRESSS_ID, name):
        return state.mergeDeep({ selectedAddressId: action.selectedAddressId });

      case getActionName(TOGGLE_ADDRESS_NOT_LISTED, name):
        return state.mergeDeep({ addressNotListed: action.addressNotListed });

      case getActionName(UPDATE_FIELD_ADDRESS_NOT_LISTED, name):
        return state.mergeDeep({
          addressDetailsNotListed: { [action.field]: action.value },
        });

      case getActionName(UPDATE_POSTCODE_FIELD, name): {
        let postcode = action.postcode?.toUpperCase();
        if (!postcode) postcode = '';
        state.setIn(['addressDetailsNotListed', 'postcode'], postcode);
        return state.mergeDeep({ postcode });
      }

      case getActionName(VALIDATE_POSTCODE_FIELD, name): {
        const postcode = state.get('postcode');
        return state.mergeDeep({ isValidPostcode: isValidPostcode(postcode) });
      }

      case getActionName(ADDRESS_SELECT, name):
        return state.mergeDeep({ selectedAddressId: action.selectedAddressId });

      case getActionName(ADDRESS_PICKER_RESET, name):
        return initialState;

      default:
        return state;
    }
  };
}
