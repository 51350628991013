import isRequestInProgress from 'redux/utils/isRequestInProgress';

class AddressPickerApi {
  // storeName is provided by address picker container
  // to allow having multiple address picker states
  static isRequestInProgress(state, storeName) {
    return isRequestInProgress(state, storeName);
  }
}

export default AddressPickerApi;
