import Immutable from 'immutable';
import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_FAIL,
} from 'constants/requestStatus';

import * as types from './constants';

import { PROCESS_3DS_PAYMENT_FAILURE } from '../FingerprintsWithChallenge/constants';

const initialState = Immutable.fromJS({
  AppError: {
    open: false,
    title: 'initial redux ERROR title',
    message: 'initial redux ERROR message',
  },
  creationError: '',
  applicationComplete: {
    error: false,
    loading: false,
    canBeResolved: false,
    message: '',
  },
  application: {
    id: null,
    status: null,
    accountNumber: undefined,
    readOnly: false,
    smartLink: null,
  },
  token: '',
  remote: {
    appointmentCode: undefined,
    partnerId: undefined,
    loading: false,
  },
  salesId: '',
  lineCheckStatus: '',
  showBrowserDialog: false,
  showStartAgainDialog: false,
  edit: {
    inProgress: false,
    service: null,
    newService: false,
  },
  registrationToken: null,
  requests: {
    registrationToken: null,
  },
  pageParameters: {},
  exclusionParameters: [],
  lead: null,
  brandPartnerId: null,
  brandPartnerAgentId: null,
  bankHolidays: {
    bankHolidays: null,
    loading: false,
    error: null,
  },
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.APP_POST_APPLICATION_CREATED_FAILURE:
      return state.mergeDeep({ creationError: action.error });

    case types.APP_POST_APPLICATION_CREATED_SUCCESS:
      return state.mergeDeep({
        application: { id: action.applicationId },
        token: action.token,
        creationError: '',
      });

    case types.APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_SUCCESS:
      return state.mergeDeep({
        application: { id: action.applicationId },
        token: action.token,
        remote: {
          partnerId: action.partnerId,
          appointmentCode: action.appointmentCode,
        },
        creationError: '',
      });

    case types.APP_CREATE_REMOTE_APPLICATION_CREATED_SUCCESS:
      return state.mergeDeep({
        application: { id: action.applicationId },
        token: action.token,
        creationError: '',
        remote: {
          appointmentCode: action.appointmentCode,
          partnerId: action.partnerId,
        },
      });

    case types.APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST:
      return state.mergeDeep({
        remote: {
          loading: true,
        },
      });

    case types.APP_PUT_REMOTE_APPLICATION_SUPPORTING_SUCCESS:
      return state.mergeDeep({
        remote: {
          loading: false,
          supportingPartnerId: action.supportingPartnerId,
        },
      });

    case types.APP_GET_APPLICATION_REQUEST:
      return state.mergeDeep({
        application: {
          status: 'request',
        },
      });

    case types.APP_GET_APPLICATION_LOADING:
      return state.mergeDeep({
        application: {
          status: 'loading',
        },
      });

    case types.APP_GET_APPLICATION_SUCCESS:
      return state.mergeDeep({
        application: {
          id: action.applicationId,
          accountNumber: action.accountNumber,
          status: 'fetched',
          smartLink: action.smartLink,
        },
      });

    case types.APP_GET_APPLICATION_FAILURE:
      return state.mergeDeep({
        application: {
          accountNumber: null,
          status: 'failed',
        },
      });

    case types.APP_POST_APPLICATION_COMPLETE_SUCCESS:
      return state.mergeDeep({
        application: {
          accountNumber: action.accountNumber,
          smartLink: action.smartLink,
          status: 'completed',
          accountId: action.accountId,
        },
      });

    case types.APP_POST_APPLICATION_COMPLETE_REQUEST:
      return state.mergeDeep({
        applicationComplete: {
          error: false,
          loading: true,
          canBeResolved: false,
          message: '',
        },
      });

    case types.APP_POST_APPLICATION_COMPLETE_FAILURE:
      return state.mergeDeep({
        applicationComplete: {
          error: true,
          loading: false,
          canBeResolved: action.returnToSummary,
          message: action.message,
        },
      });

    case types.APP_RESET_COMPLETE_REQUEST:
    case PROCESS_3DS_PAYMENT_FAILURE: // we need to clear the app wide loader
      return state.mergeDeep({
        // to process another payment
        applicationComplete: {
          error: false,
          loading: false,
          canBeResolved: false,
          message: '',
        },
      });

    case types.LINE_CHECK_STATUS_BROADBAND_REQUEST:
      return state.merge({
        lineCheckStatus: REQUEST_FETCHING,
      });

    case types.LINE_CHECK_STATUS_BROADBAND:
    case types.LINE_CHECK_STATUS_HOMEPHONE:
      return state.mergeDeep({ lineCheckStatus: action.status });

    case types.BROWSER_DIALOG_SET:
      return state.merge({
        showBrowserDialog: action.show,
      });

    case types.EDIT_ORDER:
      return state.merge({
        edit: {
          inProgress: true,
          service: action.service,
          newService: action.newService,
        },
      });

    case types.EDIT_ORDER_DONE:
      return state.merge({
        edit: {
          inProgress: false,
          service: null,
          newService: false,
        },
      });

    case types.APP_START_AGAIN_SET: {
      return state.merge({
        showStartAgainDialog: action.open,
      });
    }

    case types.APP_SALES_ID_SET: {
      return state.merge({
        salesId: action.salesId,
      });
    }

    case types.APP_REGISTRATION_TOKEN_FETCH: {
      return state.merge({
        requests: {
          registrationToken: REQUEST_FETCHING,
        },
      });
    }
    case types.APP_REGISTRATION_TOKEN_FETCH_SUCCESS: {
      return state.merge({
        registrationToken: action.token,
        requests: {
          registrationToken: REQUEST_SUCCESS,
        },
      });
    }
    case types.APP_REGISTRATION_TOKEN_FETCH_FAILURE: {
      return state.merge({
        requests: {
          registrationToken: REQUEST_FAIL,
        },
      });
    }

    case types.APP_SET_APPLICATION_READONLY:
      return state.mergeDeep({
        application: {
          readOnly: true,
        },
      });

    case types.APP_SET_PAGE_PARAMS:
      return state.set('pageParameters', Immutable.Map(action.params));
    case types.APP_SET_EXCLUSION_PARAMS:
      return state.set('exclusionParameters', Immutable.List(action.params));

    case types.APP_SET_LEAD:
      return state.merge({
        lead: action.lead,
      });

    case types.APP_SET_BRAND_PARTNER:
      return state.merge({
        brandPartnerId: action.brandPartnerId,
        brandPartnerAgentId: action.brandPartnerAgentId,
      });

    case types.APP_GET_BANK_HOLIDAYS: {
      return state.merge({
        bankHolidays: {
          loading: true,
          error: null,
        },
      });
    }
    case types.APP_GET_BANK_HOLIDAYS_SUCCESS:
      return state.mergeDeep({
        bankHolidays: {
          bankHolidays: action.bankHolidays,
          loading: false,
          error: null,
        },
      });
    case types.APP_GET_BANK_HOLIDAYS_FAILURE:
      return state.mergeDeep({
        bankHolidays: {
          bankHolidays: null,
          loading: false,
          error: action.error,
        },
      });

    default:
      return state;
  }
}
