import { ApplicationApi } from 'app/redux/modules/App/api';
import { PartnerLoginApi } from 'app/redux/modules/PartnerLogin/api';
import { STORE_NAME } from './constants';

export const getIsOptlyReady = (state) => {
  return state.getIn([STORE_NAME, 'optlyReady']);
};

export const getIsSnowplowReady = (state) =>
  state.getIn([STORE_NAME, 'snowplowReady']);

export const getSalesMethod = (state) => {
  const sessionTypes = {
    UNASSISTED: 'Unassisted',
    SALES: 'SalesAssisted',
    REMOTE: 'PartnerAssistedRemote',
    PARTNER_IN_PERSON: 'PartnerAssistedInPerson',
  };
  const isRemote = ApplicationApi.isRemoteSession(state);
  const isSales = ApplicationApi.isSalesApplication(state);
  const isOrganic = ApplicationApi.isOrganicApplication(state);
  const partnerLoggedIn = PartnerLoginApi.isPartnerLoggedIn(state);

  if (isSales) {
    return sessionTypes.SALES;
  }

  if (isRemote) {
    return sessionTypes.REMOTE;
  }

  if (partnerLoggedIn) {
    return sessionTypes.PARTNER_IN_PERSON;
  }

  if (isOrganic) {
    return sessionTypes.UNASSISTED;
  }

  return undefined;
};

export const getOrigin = (state) => {
  const origins = {
    PARTNER: 'Partner',
    SALES: 'Sales',
    REFER_A_FRIEND: 'ReferAFriend',
  };
  const partnerLoggedIn = PartnerLoginApi.isPartnerLoggedIn(state);
  const isHosted = PartnerLoginApi.getHostedId(state);
  const isSales = ApplicationApi.isSalesApplication(state);
  const rafLead = ApplicationApi.getLeadIsRaf(state);

  if (partnerLoggedIn || isHosted) {
    return origins.PARTNER;
  }

  if (isSales) {
    return origins.SALES;
  }

  if (rafLead) {
    return origins.REFER_A_FRIEND;
  }

  return undefined;
};
