/* eslint-disable prefer-template */
import {
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_LOADING,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAILURE,
  GET_ADDRESS_DETAILS_REQUEST,
  GET_ADDRESS_DETAILS_LOADING,
  GET_ADDRESS_DETAILS_SUCCESS,
  GET_ADDRESS_DETAILS_FAILURE,
  SAVE_SELECTED_ADDRESSS_ID,
  TOGGLE_ADDRESS_NOT_LISTED,
  UPDATE_FIELD_ADDRESS_NOT_LISTED,
  UPDATE_POSTCODE_FIELD,
  VALIDATE_POSTCODE_FIELD,
  ADDRESS_PICKER_RESET,
  ADDRESS_SELECT,
} from './constants';

/**
 * GET_ADDRESSES
 */
export const getAddressesRequest = (postcode) => ({
  type: GET_ADDRESSES_REQUEST,
  postcode,
});

export const getAddressesLoading = (postcode) => ({
  type: GET_ADDRESSES_LOADING,
  postcode,
});

export const reset = () => ({ type: ADDRESS_PICKER_RESET });

export const getAddressesSuccess = (response, postcode, addresses) => ({
  type: GET_ADDRESSES_SUCCESS,
  addresses,
  postcode,
});

export const getAddressesFailure = (error, postcode) => ({
  type: GET_ADDRESSES_FAILURE,
  error,
  postcode,
});

/**
 * GET_ADDRESS_DETAILS
 */
export const getAddressDetailsRequest = (uuid) => ({
  type: GET_ADDRESS_DETAILS_REQUEST,
  uuid,
});

export const getAddressDetailsLoading = (uuid) => ({
  type: GET_ADDRESS_DETAILS_LOADING,
  uuid,
});

export const getAddressDetailsSuccess = (response, uuid, details) => ({
  type: GET_ADDRESS_DETAILS_SUCCESS,
  uuid,
  details,
});

export const getAddressDetailsFailure = (error, uuid) => ({
  type: GET_ADDRESS_DETAILS_FAILURE,
  error,
  uuid,
});

export const saveSelectedAddressId = (selectedAddressId) => ({
  type: SAVE_SELECTED_ADDRESSS_ID,
  selectedAddressId,
});

export const toggleAddressNotListed = (toggle) => ({
  type: TOGGLE_ADDRESS_NOT_LISTED,
  addressNotListed: toggle,
});

export const updateFieldAddressNotListed = (value, field) => ({
  type: UPDATE_FIELD_ADDRESS_NOT_LISTED,
  value,
  field,
});

export const updatePostcodeField = (postcode) => ({
  type: UPDATE_POSTCODE_FIELD,
  postcode,
});

export const validatePostcodeField = (postcode) => ({
  type: VALIDATE_POSTCODE_FIELD,
  postcode,
});

export const selectAddress = (selectedAddressId) => ({
  type: ADDRESS_SELECT,
  selectedAddressId,
});
