import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import browserHistory from 'components/root/browserHistory';
import Root from 'components/root';
import { CONFIG_BASE_URL } from 'config';

import { Router, Route } from 'react-router-dom';

export const renderApp = () => {
  ReactDOM.render(
    <Provider store={window.store}>
      <Router history={browserHistory} basename={CONFIG_BASE_URL}>
        <Route path="/" component={Root} />
      </Router>
    </Provider>,
    document.getElementById('root')
  );
};
