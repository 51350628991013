import * as types from './constants';

export const trackPage = (pathname, addressObj) => ({
  type: types.ANALYTICS_TRACK_PAGE,
  pathname,
  addressObj,
});

export const trackEvent = (event, params) => ({
  type: types.ANALYTICS_TRACK_EVENT,
  event,
  params,
});

export const trackEcommEvent = (event, products, params) => ({
  type: types.ANALYTICS_TRACK_EVENT_ECOMM,
  event,
  products,
  params,
});

export const trackIdent = (id, params, reset = false) => ({
  type: types.ANALYTICS_TRACK_IDENT,
  id,
  params,
  reset,
});

export const setOptlyReady = (ready) => ({
  type: types.ANALYTICS_OPTLY_SET_READY,
  ready,
});

export const setSnowplowReady = (ready) => ({
  type: types.ANALYTICS_SNOWPLOW_SET_READY,
  ready,
});
