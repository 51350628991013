export const STORE_NAME = 'App';

export const APP_GET_APPLICATION_REQUEST = 'APP_GET_APPLICATION_REQUEST';
export const APP_GET_APPLICATION_LOADING = 'APP_GET_APPLICATION_LOADING';
export const APP_GET_APPLICATION_SUCCESS = 'APP_GET_APPLICATION_SUCCESS';
export const APP_GET_APPLICATION_FAILURE = 'APP_GET_APPLICATION_FAILURE';

export const APP_POST_APPLICATION_COMPLETE_REQUEST =
  'APP_POST_APPLICATION_COMPLETE_REQUEST';
export const APP_POST_APPLICATION_COMPLETE_LOADING =
  'APP_POST_APPLICATION_COMPLETE_LOADING';
export const APP_POST_APPLICATION_COMPLETE_SUCCESS =
  'APP_POST_APPLICATION_COMPLETE_SUCCESS';
export const APP_POST_APPLICATION_COMPLETE_FAILURE =
  'APP_POST_APPLICATION_COMPLETE_FAILURE';
export const APP_USER_LOGOUT = 'APP_USER_LOGOUT';

export const LINE_CHECK_STATUS_BROADBAND_REQUEST =
  'LINE_CHECK_STATUS_BROADBAND_REQUEST';
export const LINE_CHECK_STATUS_BROADBAND = 'LINE_CHECK_STATUS_BROADBAND';
export const LINE_CHECK_STATUS_HOMEPHONE = 'LINE_CHECK_STATUS_HOMEPHONE';

export const APP_RESET_COMPLETE_REQUEST = 'APP_RESET_COMPLETE_REQUEST';

export const APP_POST_APPLICATION_CREATED_PARTNER_REQUEST =
  'APP_POST_APPLICATION_CREATED_PARTNER_REQUEST';
export const APP_POST_APPLICATION_CREATED_ORGANIC_REQUEST =
  'APP_POST_APPLICATION_CREATED_ORGANIC_REQUEST';
export const APP_POST_APPLICATION_CREATED_REMOTE_REQUEST =
  'APP_POST_APPLICATION_CREATED_REMOTE_REQUEST';
export const APP_POST_APPLICATION_CREATED_SALES_REQUEST =
  'APP_POST_APPLICATION_CREATED_SALES_REQUEST';
export const APP_POST_APPLICATION_CREATED_SALES_IAM_REQUEST =
  'APP_POST_APPLICATION_CREATED_SALES_IAM_REQUEST';
export const APP_POST_APPLICATION_CREATED_SALES_TT_JWT_REQUEST =
  'APP_POST_APPLICATION_CREATED_SALES_TT_JWT_REQUEST';
export const APP_POST_APPLICATION_CREATED_SUCCESS =
  'APP_POST_APPLICATION_CREATED_SUCCESS';
export const APP_POST_APPLICATION_CREATED_FAILURE =
  'APP_POST_APPLICATION_CREATED_FAILURE';

export const APP_START_AGAIN = 'APP_START_AGAIN';
export const APP_START_AGAIN_SET = 'APP_START_AGAIN_SET';

export const ORDER_FAILED_EVENT = 'Order Failed';
export const ORDER_SUBMIT_EVENT = 'Order Submitted';
export const PRODUCTS_SELECTED_EVENT = 'Products Selected';

export const BROWSER_DIALOG_SET = 'BROWSER_DIALOG_SET';

export const RENDER_APP = 'RENDER_APP';

export const EDIT_ORDER = 'EDIT_ORDER';
export const EDIT_ORDER_DONE = 'EDIT_ORDER_DONE';
export const NEW_MOBILE_EDIT_PRODUCT = 'NEW_MOBILE_EDIT_PRODUCT';

export const APP_CREATE_REMOTE_APPLICATION_CREATED_SUCCESS =
  'APP_CREATE_REMOTE_APPLICATION_CREATED_SUCCESS';

export const APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST =
  'APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST';
export const APP_PUT_REMOTE_APPLICATION_SUPPORTING_SUCCESS =
  'APP_PUT_REMOTE_APPLICATION_SUPPORTING_SUCCESS';

export const APP_SALES_ID_SET = 'APP_SALES_ID_SET';

export const APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_REQUEST =
  'APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_REQUEST';
export const APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_SUCCESS =
  'APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_SUCCESS';
export const APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_FAILURE =
  'APP_POST_APPLICATION_CREATED_REMOTE_SUPPORT_FAILURE';

export const APP_REGISTRATION_TOKEN_FETCH = 'APP_REGISTRATION_TOKEN_FETCH';
export const APP_REGISTRATION_TOKEN_FETCH_SUCCESS =
  'APP_REGISTRATION_TOKEN_FETCH_SUCCESS';
export const APP_REGISTRATION_TOKEN_FETCH_FAILURE =
  'APP_REGISTRATION_TOKEN_FETCH_FAILURE';

export const APP_SET_APPLICATION_READONLY = 'APP_SET_APPLICATION_READONLY';
export const APP_SET_PAGE_PARAMS = 'APP_SET_PAGE_PARAMS';

export const APP_JOURNEY_START = 'APP_JOURNEY_START';

export const APP_SET_LEAD = 'APP_SET_LEAD';
export const APP_SET_BRAND_PARTNER = 'APP_SET_BRAND_PARTNER';

export const APP_GET_BANK_HOLIDAYS = 'APP_GET_BANK_HOLIDAYS';
export const APP_GET_BANK_HOLIDAYS_SUCCESS = 'APP_GET_BANK_HOLIDAYS_SUCCESS';
export const APP_GET_BANK_HOLIDAYS_FAILURE = 'APP_GET_BANK_HOLIDAYS_FAILURE';

export const APP_SET_EXCLUSION_PARAMS = 'APP_SET_EXCLUSION_PARAMS';

export const YOUR_DETAILS_VARIANT = 'your-details-variant';
export const CONSOLIDATION = 'consolidation';
export const LEGACY = 'legacy';
export const JTC_COOKIE_POLICY_ACCEPTED = 'jtc-cookie-policy-accepted';
