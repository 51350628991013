/* eslint-disable no-confusing-arrow */
export const COPY_STREET_NAME = 'a street name';
export const COPY_TOWN = 'a city/town';
export const COPY_BUILDING = 'a building name or number';

const validateStreet = (street) => (street ? false : COPY_STREET_NAME);
const validateCity = (city) => (city ? false : COPY_TOWN);

const validateBuilding = (buildingName, buildingNumber) => {
  if (!buildingName && !buildingNumber) {
    return COPY_BUILDING;
  }

  return false;
};

const getAdressNotListedValidationErrors = (addressDetailsNotListed) => {
  const {
    buildingName,
    buildingNumber,
    street,
    town,
  } = addressDetailsNotListed;

  const fieldErrors = {
    building: validateBuilding(buildingName, buildingNumber),
    street: validateStreet(street),
    town: validateCity(town),
  };

  const errors = Object.values(fieldErrors).filter((e) => e);

  if (!errors.length) {
    return {
      isValid: true,
    };
  }

  return {
    isValid: false,
    details: `Please enter ${errors.reduce(
      (a, b, i, arr) => `${a}${i === arr.length - 1 ? ', and ' : ', '}${b}`
    )}`,
  };
};

export default getAdressNotListedValidationErrors;
