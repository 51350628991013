import { getActionName } from './utils';
import * as types from './types';

export default function createActions(prefix) {
  const getAddressList = (postcode) => ({
    type: getActionName(types.GET_ADDRESS_LIST_REQUEST, prefix),
    postcode,
  });

  const getAddressListSuccess = (addresses) => ({
    type: getActionName(types.GET_ADDRESS_LIST_SUCCESS, prefix),
    addresses,
  });

  const getAddressListFailure = (error) => ({
    type: getActionName(types.GET_ADDRESS_LIST_FAILURE, prefix),
    error,
  });

  const setAddressSelection = (uuid) => ({
    type: getActionName(types.SET_ADDRESS_SELECTION, prefix),
    uuid,
  });

  const setManualEntry = (manualEntry) => ({
    type: getActionName(types.SET_MANUAL_ENTRY, prefix),
    manualEntry,
  });

  const setManualEntryField = (field, value) => ({
    type: getActionName(types.SET_MANUAL_ENTRY_FIELD, prefix),
    field,
    value,
  });

  return {
    getAddressList,
    getAddressListSuccess,
    getAddressListFailure,
    setAddressSelection,
    setManualEntry,
    setManualEntryField,
  };
}
