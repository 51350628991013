import { connector } from 'redux/utils/connector';
import AddressPickerUI from 'components/modules/Shared/AddressPicker';
import {
  getNamedAction,
  getNamedStoreName,
} from 'redux/modules/AddressPicker/utils';
import * as AddressPickerActions from 'redux/modules/AddressPicker/actions';
import AddressPickerApi from 'redux/modules/AddressPicker/api';
import { NOT_LISTED_ID, NAMES } from './constants';
import getAddressNotListedValidationErrors from './validation.js';

// take all actions then replace them with proxies using getNamedAction.
// Do this en masse because it makes everyone's lives easier
const mapActions = (name) => {
  return Object.entries(AddressPickerActions)
    .map(([key, action]) => {
      return [key, getNamedAction(action, name)];
    })
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      };
    }, {});
};

const mapState = (storeName, state) => {
  const addressDetailsNotListed = state
    .getIn([storeName, 'addressDetailsNotListed'])
    .toJS();

  return {
    notListedId: NOT_LISTED_ID,
    isValidForm: getAddressNotListedValidationErrors(addressDetailsNotListed),
    isRequestInProgress: AddressPickerApi.isRequestInProgress(state, storeName),
  };
};

// I would've used some form of wrapper component to allow sending the `name`
// as a prop, but returning a connector, which returns a react-redux connector,
// and expecting React to figure that out is a bit too lofty of an expectation
// So here's a helper function to give you something exportable instead
const getPickerWithState = (name = null) => {
  const storeName = getNamedStoreName(name);
  return connector(
    storeName,
    mapState.bind(this, storeName),
    mapActions(name)
  )(AddressPickerUI);
};

export const PreviousAddressPicker = getPickerWithState(
  NAMES.detailsPreviousAddress
);
export default getPickerWithState();
