import { STORE_NAME } from './constants';

export function getActionName(action, reducerName) {
  if (!reducerName) {
    return action;
  }

  return `${action}_${reducerName}`;
}

export const getNamedStoreName = (name) =>
  name ? `${STORE_NAME}_${name}` : STORE_NAME;

/**
 * modify an action creator by appending a name to action types. To ensure
 * that actions are confined to their relative store scope.
 * This is done dynamically, by modifying the action creator function itself,
 * to avoid the need to create bespoke action creators for each state.
 *
 * Functions by returning a proxy of the original action creator function, with
 * the type modified with the name appened. Additionally, the name itself is
 * added as a meta-property (using the $$ prefix so it never, ever conflicts
 * with stuff. Redux-saga uses @@ for it's own similar property prefixes, but
 * that makes javascript freak out unless the keys are strings)
 *
 * @param {function} actionCreator - the action creator function
 * @param {string} name - the name to be appended to the type property
 * @returns {function|Proxy} - return the proxy with modified type, or if the name is blank, just return the actionCreator directly
 */
export function getNamedAction(actionCreator, name = null) {
  if (!name) return actionCreator;

  const handler = {
    apply: (target, thisArg, args) => {
      const action = target(...args);
      return {
        ...action,
        $$name: name,
        type: getActionName(action.type, name),
      };
    },
  };
  return new Proxy(actionCreator, handler);
}
