import { takeLatest, call, put } from 'redux-saga/effects';
import fetchAddressesService from 'redux/services/getSearchAddressPostcode';
import ukAddressParser from 'redux/utils/UKAddressParser';
import ukAddressSorter from 'redux/utils/UKAddressSorter';
import isValidPostcode from 'redux/services/isValidPostcode';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import createActions from './actions';
import * as types from './types';
import { getActionName } from './utils';

export default function constructSagas(prefix) {
  const actions = createActions(prefix);
  function* getAddressFromPostCode(postcode) {
    if (!isValidPostcode(postcode) || postcode.length <= 2) {
      return null;
    }
    const response = yield call(fetchAddressesService, postcode);
    const addresses = response?.data;

    return addresses
      .map((a) => ({
        ...a,
        primaryText: ukAddressParser(a),
      }))
      .sort((a, b) => ukAddressSorter(a, b));
  }

  function* handleChangePostcode({ postcode }) {
    try {
      const addresses = yield call(getAddressFromPostCode, postcode);
      yield put(actions.getAddressListSuccess(addresses));
    } catch (error) {
      Sentry.log(error, ERRORS.GET_ADDRESS_LIST);
      yield put(actions.getAddressListFailure(error));
    }
  }

  function* combinedSagas() {
    yield takeLatest(
      getActionName(types.GET_ADDRESS_LIST_REQUEST, prefix),
      handleChangePostcode
    );
  }

  return combinedSagas;
}
