import { takeLatest, call, fork, put, take, cancel } from 'redux-saga/effects';
import fetchAddressesService from 'redux/services/getSearchAddressPostcode';
import fetchAddressDetailsService from 'redux/services/getAddressByUUID';
import isValidPostcode from 'redux/services/isValidPostcode';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { getActionName, getNamedAction } from './utils';
import * as actions from './actions';
import {
  GET_ADDRESSES_REQUEST,
  GET_ADDRESS_DETAILS_REQUEST,
  errors,
} from './constants';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * handleGetAddresses search task
 * @param  {string} postcode
 */

export function* handleGetAddresses(postcode, { $$name }) {
  // Debounce and lock the process
  yield call(delay, 500);

  if (postcode && isValidPostcode(postcode)) {
    yield put(getNamedAction(actions.getAddressesLoading, $$name)(postcode));
    try {
      const response = yield call(fetchAddressesService, postcode);
      const addresses = response.data;
      if (addresses.length > 0) {
        yield put(
          getNamedAction(actions.getAddressesSuccess, $$name)(
            response,
            postcode,
            addresses
          )
        );
      } else {
        yield put(
          getNamedAction(actions.getAddressesFailure, $$name)(
            errors.ERR_NOT_FOUND,
            postcode
          )
        );
        yield put(getNamedAction(actions.toggleAddressNotListed, $$name)(true));
      }
    } catch (error) {
      yield put(
        getNamedAction(actions.getAddressesFailure, $$name)(
          errors.ERR_CONNECTION,
          postcode
        )
      );
      yield put(getNamedAction(actions.toggleAddressNotListed, $$name)(true));
    }
  } else {
    yield put(
      getNamedAction(actions.getAddressesFailure, $$name)(
        errors.ERR_INVALID,
        postcode
      )
    );
    // No need for error dialog, the error is displayed under the input field
  }
}

/**
 * handleSearch search task
 * @param  {string} postcode
 */

export function* handleGetAddressDetails({ $$name, ...action }) {
  const { uuid } = action;
  if (uuid) {
    yield put(getNamedAction(actions.getAddressDetailsLoading, $$name)(uuid));
    try {
      const response = yield call(fetchAddressDetailsService, uuid);
      const data = response.data;
      yield put(
        getNamedAction(actions.getAddressDetailsSuccess, $$name)(
          response,
          uuid,
          data
        )
      );
    } catch (error) {
      Sentry.log(error, ERRORS.GET_ADDRESS_DETAILS);
      yield put(
        getNamedAction(actions.getAddressDetailsFailure, $$name)(
          errors.ERR_CONNECTION,
          uuid
        )
      );

      const errorMessage = `We encountered an error while retrieving the address details. ${error}`;
      yield put(openErrorGlobalDialog(errorMessage));
    }
  }
}

export function* combinedSagas(name = null) {
  yield takeLatest(
    getActionName(GET_ADDRESS_DETAILS_REQUEST, name),
    handleGetAddressDetails
  );

  let task;
  while (true) {
    // wait for the action and read the query
    const { postcode, ...action } = yield take(
      getActionName(GET_ADDRESSES_REQUEST, name)
    );
    if (task) {
      yield cancel(task);
    }
    // Create a worker to proceed with the search
    task = yield fork(handleGetAddresses, postcode, action);
  }
}
