export const STORE_NAME = 'AddressPicker';
/*
 * Put address picker states here, they will programatically generate both
 * reducers and sagas by mapping the array'd object. See:
 *  - `app/redux/index.js` for reducers
 *  - `app/redux/sagas.js` for sagas
 *
 * The object itself is, however, necessary because the names must be accessed
 * directly to export the component containers:
 * `app/redux/modules/AddressPicker/container.js`
 */
export const NAMES = {
  detailsPreviousAddress: 'detailsPreviousAddress',
  detailsBillingAddress: 'detailsBillingAddress',
};

export const namesArray = Object.values(NAMES);

export const GET_ADDRESSES_REQUEST = 'GET_ADDRESSES_REQUEST';
export const GET_ADDRESSES_LOADING = 'GET_ADDRESSES_LOADING';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE';
export const NOT_LISTED_ID = 'NOT_LISTED_ID';

export const GET_ADDRESS_DETAILS_REQUEST = 'GET_ADDRESS_DETAILS_REQUEST';
export const GET_ADDRESS_DETAILS_LOADING = 'GET_ADDRESS_DETAILS_LOADING';
export const GET_ADDRESS_DETAILS_SUCCESS = 'GET_ADDRESS_DETAILS_SUCCESS';
export const GET_ADDRESS_DETAILS_FAILURE = 'GET_ADDRESS_DETAILS_FAILURE';
export const SAVE_SELECTED_ADDRESSS_ID = 'SAVE_SELECTED_ADDRESSS_ID';

export const TOGGLE_ADDRESS_NOT_LISTED = 'TOGGLE_ADDRESS_NOT_LISTED';
export const UPDATE_FIELD_ADDRESS_NOT_LISTED =
  'UPDATE_FIELD_ADDRESS_NOT_LISTED';
export const UPDATE_POSTCODE_FIELD = 'UPDATE_POSTCODE_FIELD';
export const VALIDATE_POSTCODE_FIELD = 'VALIDATE_POSTCODE_FIELD';

export const ADDRESS_PICKER_RESET = 'ADDRESS_PICKER_RESET';
export const ADDRESS_SELECT = 'ADDRESS_SELECT';

export const errors = {
  ERR_CONNECTION: 'CONNECTION_ERROR',
  ERR_NOT_FOUND: 'NO_ADDRESSES_FOUND',
  ERR_INVALID: 'INVALID_ERROR',
};

export const errorMessages = {
  [errors.ERR_CONNECTION]:
    'There was an error searching for your address, please enter it manually',
  [errors.ERR_NOT_FOUND]:
    "We couldn't find any address for this postcode, please enter it manually",
  [errors.ERR_INVALID]: 'Please enter a valid postcode',
};
