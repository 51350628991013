import isEmpty from 'lodash/isEmpty';
import { STORE_NAME } from './constants';
import { REQUEST_FETCHING } from 'constants/requestStatus';
import { PartnerLoginApi } from 'app/redux/modules/PartnerLogin/api';

export class ApplicationApi {
  static isComplete(state) {
    return state.get(STORE_NAME).toJS().applicationComplete;
  }

  static getId(state) {
    return state.getIn([STORE_NAME, 'application', 'id']);
  }

  static getAccountNumber(state) {
    return state.getIn([STORE_NAME, 'application', 'accountNumber']);
  }

  static getAccountId(state) {
    return state.getIn([STORE_NAME, 'application', 'accountId']);
  }

  static getSmartLink(state) {
    return state.getIn([STORE_NAME, 'application', 'smartLink']);
  }

  static isFinished(state) {
    return state.getIn([STORE_NAME, 'application', 'status']) === 'completed';
  }

  static hasLineCheckResults(state) {
    return !!state.get(STORE_NAME).toJS().lineCheckStatus;
  }

  static isLineCheckInProgress(state) {
    return state.get(STORE_NAME).toJS().lineCheckStatus === REQUEST_FETCHING;
  }

  static newLineRequired(state) {
    return (
      ApplicationApi.hasLineCheckResults(state) &&
      state.get(STORE_NAME).toJS().lineCheckStatus.newLineRequired
    );
  }

  static requires3dsPayment(state) {
    return ApplicationApi.isSalesApplication(state)
      ? false
      : state.get('OrderConfirmation').toJS().payment;
  }

  static getRemoteAppointmentCode(state) {
    return state.getIn([STORE_NAME, 'remote', 'appointmentCode']);
  }

  static getRemotePartnerId(state) {
    return state.getIn([STORE_NAME, 'remote', 'partnerId']);
  }

  static getRemoteSupportingPartnerId(state) {
    return state.getIn([STORE_NAME, 'remote', 'supportingPartnerId']);
  }

  static isRemoteSession(state) {
    const code = ApplicationApi.getRemoteAppointmentCode(state);
    const partnerId = ApplicationApi.getRemotePartnerId(state);
    return !isEmpty(code) && !isEmpty(partnerId);
  }

  static getToken(state) {
    return state.getIn([STORE_NAME, 'token']);
  }

  static getCurrentApplication(state) {
    return state.getIn([STORE_NAME, 'application', 'id']);
  }

  static getSalesId(state) {
    return state.getIn([STORE_NAME, 'salesId']);
  }

  static getBrandPartnerAgentId(state) {
    return state.getIn([STORE_NAME, 'brandPartnerAgentId']);
  }

  static getBrandPartnerId(state) {
    return state.getIn([STORE_NAME, 'brandPartnerId']);
  }

  static isSalesApplication(state) {
    return !!ApplicationApi.getSalesId(state);
  }

  static isTalkTalkApplication(state) {
    return ApplicationApi.getBrandPartnerId(state) === 'TalkTalk';
  }

  static isOrganicApplication(state) {
    return !(
      PartnerLoginApi.isPartnerLoggedIn(state) ||
      ApplicationApi.isRemoteSession(state)
    );
  }

  static getRegistrationToken(state) {
    return state.getIn([STORE_NAME, 'registrationToken']);
  }

  static getRequestStatus(state, request) {
    if (!request) {
      throw new TypeError('request name is required');
    }

    return state.getIn([STORE_NAME, 'requests', request]);
  }

  static getIsReadOnly(state) {
    return state.getIn([STORE_NAME, 'application', 'readOnly']);
  }

  static getEligibility(state, prop) {
    return state.getIn([STORE_NAME, 'application', 'eligibility', prop]);
  }

  static getEditInProgress(state) {
    return state.getIn([STORE_NAME, 'edit', 'inProgress']);
  }

  static getEditingNewService(state) {
    return state.getIn([STORE_NAME, 'edit', 'newService']);
  }

  static getEditService(state) {
    return state.getIn([STORE_NAME, 'edit', 'service']);
  }

  static getPageParams(state) {
    return state.getIn([STORE_NAME, 'pageParameters']).toJS();
  }

  static getLead(state) {
    return state.getIn([STORE_NAME, 'lead'])?.toJS();
  }

  static getLeadIsRaf(state) {
    const lead = ApplicationApi.getLead(state);
    if (!lead) return false;
    const validRafSources = [
      'CUSTOMER_REFER_A_FRIEND',
      'REFERRAL_PORTAL_CUSTOMER_REFER_A_FRIEND',
    ];

    return validRafSources.includes(lead.source);
  }

  static getBankHolidays(state) {
    return state.getIn([STORE_NAME, 'bankHolidays', 'bankHolidays']);
  }

  static getExcludeParams(state) {
    return state.getIn([STORE_NAME, 'exclusionParameters']).toJS();
  }
}
